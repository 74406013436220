@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./fonts/lato-semibold.woff2') format('woff2'),
    url('./fonts/lato-semibold.woff') format('woff'),
    url('./fonts/lato-semibold.ttf') format('truetype'),
    url('./fonts/lato-semibold.eot') format('embedded-opentype');

  font-display: swap;
}

@font-face {
  font-family: 'DrukWideWeb';
  src: url('./fonts/DrukWide-Super-Web.woff2') format('woff2'),
    url('./fonts/DrukWide-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'DrukWideMedium';
  src: url('./fonts/DrukWide-Medium.woff') format('woff2'),
    url('./fonts/DrukWide-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

* {
  overflow-anchor: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.text-row {
  border-radius: 4px;
}

em {
  color: #ff6600;
  font-style: normal;
}

@keyframes react-placeholder-pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

#root > [aria-hidden='true'] {
  filter: blur(8px);
}

body[style='padding-right: 14px; overflow: hidden;']
  .progress-indicator-container {
  padding-right: 14px;
}
body[style='padding-right: 15px; overflow: hidden;']
  .progress-indicator-container {
  padding-right: 15px;
}
body[style='padding-right: 17px; overflow: hidden;']
  .progress-indicator-container {
  padding-right: 17px;
}
body[style='padding-right: 21px; overflow: hidden;']
  .progress-indicator-container {
  padding-right: 21px;
}

.grecaptcha-badge {
  visibility: hidden;
}

#CybotCookiebotDialog {
  font-family: 'Lato' !important;
}

.CybotCookiebotDialogDetailBodyContentCookieInfoTitle {
  word-break: break-all;
}

a#CybotCookiebotDialogPoweredbyCybot {
  display: none;
}
